<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="earth"></b-icon>
          <span>{{ $ml.get('proxies_proxy') }}</span>
        </h4>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-2">
        <b-button tag="router-link" type="is-success" outlined :to="{ path: `/proxies/buy` }">
          <span>{{ $ml.get('proxies_buy_proxy_btn') }}</span>
        </b-button>
      </div>
      <div class="column is-2">
        <b-button tag="router-link" type="is-primary" outlined :to="{ path: `/proxies/create` }">
          <span>{{ $ml.get('proxies_create_own_btn') }}</span>
        </b-button>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-10">
        <b-table
            :data="items"
            :paginated="true"
            :bordered="true"
            :striped="true"
            :loading="loading"
            :per-page="perPage">
          <b-table-column field="mark" :label="$ml.get('proxies_mark')" searchable cell-class="leftal" v-slot="props">
            {{ props.row.mark }}
          </b-table-column>
          <b-table-column field="country" width="50" :label="$ml.get('proxies_country')" sortable searchable cell-class="leftal"
                          v-slot="props">
            <span v-if="props.row.country">{{ props.row.country }}</span>
            <span v-if="!props.row.country">-</span>
          </b-table-column>
          <b-table-column field="proxy" :label="$ml.get('proxies_proxy')" searchable cell-class="leftal" v-slot="props">
            {{ props.row.proxy }}
          </b-table-column>
          <b-table-column field="rooms" width="50" :label="$ml.get('proxies_accounts_count')" sortable cell-class="leftal" v-slot="props">
            {{ props.row.rooms }}
          </b-table-column>
          <b-table-column field="expired_at" width="170" :label="$ml.get('proxies_expired_at')" sortable cell-class="leftal"
                          v-slot="props">
            <span v-if="!props.row.is_user && props.row.expired_at < 0" type="is-danger">
              {{ $ml.get('proxies_expired') }}
            </span>
            <span v-if="!props.row.is_user && props.row.expired_at >= 0" type="is-danger">
              {{ props.row.expired_at_hr }}
              <br><a @click="renew(props.row.id)">{{ $ml.get('proxies_renew_link') }}</a>
            </span>
            <span v-if="props.row.is_user">-</span>
          </b-table-column>
          <b-table-column v-slot="props" width="100">
            <ul class="horizontal">
              <li v-if="props.row.is_user">
                <b-button tag="router-link" size="is-small" type="is-info" outlined
                          :to="`/proxies/create?id=${props.row.id}`" :title="$ml.get('common_edit')">
                  <b-icon icon="pencil" size="is-small"></b-icon>
                </b-button>
              </li>
              <li>
                <b-button size="is-small" type="is-danger" outlined @click="delete_(props.row.id)" :title="$ml.get('common_delete')">
                  <b-icon icon="delete" size="is-small"></b-icon>
                </b-button>
              </li>
            </ul>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Index",
  data() {
    return {
      perPage: 12,
      currentPage: 1,
      loading: false,
      items: [],
      formLoading: false,
      errors: [],
    }
  },
  computed: {},
  methods: {
    retrieve: function () {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `proxy/index`).then(resp => {
        this.items = resp.data.result.items
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    },
    // switch_renewal: function (id) {
    //   if (confirm(this.$ml.get('proxies_renew_confirm'))) {
    //     this.loading = true
    //     Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + `proxy/switch_renewal?id=${id}`).then(resp => {
    //     }).catch(err => {
    //       this.flashMessage.error({
    //         title: this.$ml.get('common_request_failed'),
    //         message: this.$ml.get('common_request_failed_message'),
    //       })
    //     }).finally(() => {
    //       this.loading = false
    //     })
    //   }
    // },
    renew: function (id) {
      if (confirm(this.$ml.get('proxies_renew_confirm'))) {
            this.loading = true
            Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + `proxy/renew?id=${id}`).then(resp => {
              this.retrieve()
              this.flashMessage.success({
                title: this.$ml.get('common_request_success'),
              })
            }).catch(err => {
              this.flashMessage.error({
                title: this.$ml.get('common_request_failed'),
                message: this.$ml.get('common_request_failed_message'),
              })
            }).finally(() => {
              this.loading = false
            })
      }
    },
    delete_: function (id) {
      if (confirm(this.$ml.get('proxies_delete_confirm'))) {
        this.loading = true
        Vue.prototype.$http.delete(process.env.VUE_APP_API_HOST + `proxy/index?id=${id}`).then(resp => {
          this.retrieve()
          this.flashMessage.success({
            title: this.$ml.get('common_request_success'),
          })
        }).catch(err => {
          this.flashMessage.error({
            title: this.$ml.get('common_request_failed'),
            message: this.$ml.get('common_request_failed_message'),
          })
        }).finally(() => {
          this.loading = false
        })
      }
    },
  },
  mounted() {
    this.retrieve()
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_proxies_index')
    }
  }
}
</script>

<style scoped>
</style>